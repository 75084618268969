.empty-row td {
  text-align: left !important;
  font-weight: bold;
  font-size: 16px !important;
  /* text-decoration: underline !important; */
  color: #1ed760 !important;
}

.device-protection-row {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.device-protection-row td {
   font-size: 14px !important;
}

.amber-row td {
  background-color: #F1822A !important;
}
