@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gradient-to-b;
  @apply from-app-bgStart;
  @apply to-app-bgEnd;
  @apply text-white;
  @apply m-0;
  @apply min-h-screen;
  @apply h-full;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.animation-1 {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.table-css thead th {
  background-color: #333F48 !important;
  color: #37E7A7 !important;
  /* color: #1ED760 !important; */
  font-weight: bold;
  font-size: 14px !important;
}

.table-css thead tr th {
  border-color: #333F48 !important;
}

.table-css tbody tr td {
  background-color: #F0F0F5;
  color: black !important;
  border-color: #fff !important;
  font-size: 12px !important;
}

.table-css tbody tr td:active a {
  color: #fff !important;
  background-color: #8223D2 !important;
  /* background-color: #1ED760 !important; */
}

.table-css .ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover :hover {
  background-color: #8223D2;
  /* background-color: #1ED760 !important; */
  color: #fff !important;
}

/* .table-css tr:nth-child(2n) td {
  background-color: #fbfbfb;
}
.table-css thead {
  background-color: #f1f1f1;
} */

.anticon {
  color: rgb(255, 255, 255) !important;
}

.ant-btn-primary {
  color: #000;
  background-color: #4096ff !important;
}

.ant-table-wrapper .ant-table-pagination {
  background-color: #F0F0F5 !important;
}

.ant-pagination .ant-pagination-item {
  color: #000 !important;
  background-color: #fff !important;
}

.ant-pagination .ant-pagination-item-active:active a {
  color: #000 !important;
  background-color: #fff !important;
}

.ant-pagination-item-ellipsis {
  color: #000 !important;
}

.ant-table-body {
  max-width: 100vw;
  /* overflow-x: scroll;
  position: relative; */
  scrollbar-color: #bebebe;
  scrollbar-width: thick;
  /* -ms-overflow-style: none; */
}

.ant-table-body::-webkit-scrollbar {
  height: 10px;
}

.ant-table-body:hover::-webkit-scrollbar-thumb {
  height: 10px;
  background-color: #969696;
}

.ant-table-header {
  max-width: 100vw;
  /* overflow-x: scroll; */
  /* position: relative; */
  /* scrollbar-color: #bebebe #bebebe; */
  scrollbar-width: thin;
  /* -ms-overflow-style: none; */
}

.ant-table-header::-webkit-scrollbar {
  height: 10px;
}

.ant-table-header:hover::-webkit-scrollbar-thumb {
  height: 10px;
  background-color: #969696;
}

.ant-pagination .ant-pagination-item {
  color: #fff !important;
  background-color: #fff !important;
  display: none;
}

.ant-pagination-prev:before {
  content: "Prev";
  color: #000;
  margin-right: 20px !important;
}

.ant-pagination-next:before {
  content: "Next";
  color: #000;
}

.anticon-left {
  display: none !important;
}

.anticon-right {
  display: none !important;
}

.ant-pagination-item-ellipsis {
  visibility: hidden;
  display: none !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wave {
  width: 5px;
  height: 40px;
  background: linear-gradient(45deg, #148031, #5df5c7);
  margin: 1px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}

.wave:nth-child(2) {
  animation-delay: 0.1s;
}

.wave:nth-child(3) {
  animation-delay: 0.2s;
}

.wave:nth-child(4) {
  animation-delay: 0.3s;
}

.wave:nth-child(5) {
  animation-delay: 0.4s;
}

.wave:nth-child(6) {
  animation-delay: 0.5s;
}

.wave:nth-child(7) {
  animation-delay: 0.6s;
}

.wave:nth-child(8) {
  animation-delay: 0.7s;
}

.wave:nth-child(9) {
  animation-delay: 0.8s;
}

.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.ant-pagination-item-ellipsis:hover {
  visibility: hidden;
  display: none;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin-right: 20px !important;
}

.ant-pagination .ant-pagination-jump-next {
  display: none !important;
}

.Container {
  text-align: center;
  position: relative;
  height: 100vh;
}

.Container-content {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.MainTitle {
  display: block;
  font-size: 2rem;
  font-weight: lighter;
  text-align: center;
}

.MainDescription {
  max-width: 50%;
  font-size: 1.2rem;
  font-weight: lighter;
}

.MainGraphic {
  position: relative;
}

.Cog {
  width: 7rem;
  height: 7rem;
  fill: #6aafe6;
  transition: easeInOutQuint();
  animation: CogAnimation 5s infinite;
  margin: auto;
}

@keyframes CogAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tick-flip-panel {
  background-color: #310454 !important;
  color: white !important;
}

/* Animation property */
.amber-cards {
  animation: amber-anime 3s ease-in-out infinite;
}

@keyframes amber-anime {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
    box-shadow: 0 0 20px 0 #F1822A;
    border-radius: 10%;
  }

  100% {
    transform: scale(1);
  }
}


.red-cards {
  animation: red-anime 1s ease-in-out infinite;
}

@keyframes red-anime {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
    box-shadow: 0 0 20px 0 #DB1B1B;
    border-radius: 12%;
  }

  100% {
    transform: scale(1);
  }
}

.yellow-cards {
  animation: wiggle 1s ease-in-out infinite;
}


.green-cards:hover>#label>#label1 {
  color: #008662;
}

.amber-cards:hover>#label>#label1 {
  color: #F1822A;
}

.yellow-cards:hover>#label>#label1 {
  color: yellow;
}

.red-cards:hover>#label>#label1 {
  color: red;
}

.amber-cards:hover {
  animation: 2s ease-in;
  animation-delay: 350ms;
  transform: scale(125);
}

.yellow-cards:hover {
  animation: 2s ease-in;
  animation-delay: 350ms;
  transform: scale(125);
}

.red-cards:hover {
  animation: 2s ease-in;
  animation-delay: 350ms;
  transform: scale(125);
}

/* Keyframes */
@keyframes wiggle {

  0%,
  7% {
    transform: rotateZ(0);
  }

  15% {
    transform: rotateZ(-2deg);
  }

  20% {
    transform: rotateZ(2deg);
  }

  25% {
    transform: rotateZ(-2deg);
  }

  30%,
  100% {
    transform: rotateZ(0);
  }
}

@keyframes red-wiggle {

  0%,
  100% {
    transform: scale(1.1);
  }

  0%,
  7% {
    transform: rotateZ(0);
  }

  15%,
  100% {
    transform: rotateZ(10);
  }
}

.modal-container {
  transform: scale(0);
}

.modal-container-one {
  transform: scaleY(0.01) scaleX(0);
  animation: unfoldIn 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modal-container-one-out {
  transform: scale(1);
  animation: unfoldOut 2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes unfoldIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }

  50% {
    transform: scaleY(0.005) scaleX(1);
  }

  100% {
    transform: scaleY(1) scaleX(1);
  }
}

@keyframes unfoldOut {
  0% {
    transform: scaleY(1) scaleX(1);
  }

  50% {
    transform: scaleY(0.005) scaleX(1);
  }

  100% {
    transform: scaleY(0.005) scaleX(0);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.scroll-body::-webkit-scrollbar {
  width: 1px;
}

.scroll-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll-body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.bgImage {
  position: relative;
  height: 100%;
}

.bgImage:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("./assets/icons/bgIcon.svg");
  background-position: top;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  z-index: -1;
  opacity: 0.1;
}

.loadingBgImage {
  position: relative;
  height: 100vh;
  width: 100%;
  margin: auto;
}

.loadingBgImage:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("./assets/icons/loading-bg-image.svg");
  background-position: top;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  z-index: -1;
  opacity: 1;
}

.default-menu {
  background: linear-gradient(180deg, #CB5AFF 0%, #6700B8 100%);
  border: 1px solid #CB5AFF
}

.selected-menu {
  background: linear-gradient(180deg, #6B8BFF 0%, #253BAA 100%);
  border: 1px solid #6B8BFF
}

#menuIcon.selected-menu {
  background: linear-gradient(180deg, #FFFFFF 0%, #A5AAAF 100%);
  border: 1px solid #6700B8;
  box-shadow: 0px 0px 10px rgba(130, 35, 210, 0.8);
}

.flip-countdown {
  text-align: center;
  perspective: 400px;
  margin: 4px auto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --background-color: #310454;
  --background-bottom-color: #310454;
  --text-color: #ccc;
  --text-bottom-color: #310454;
}



.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom {
  color: var(--text-bottom-color);
  position: absolute;
  top: 50%;
  left: 0;
  padding: 2px;
  background-color: #310454 !important;
  border-radius: 0 0 0.15em 0.15em;
  pointer-events: none;
  overflow: hidden;
}

.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__top,
.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom,
.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::before,
.flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::after {
  background-color: #310454 !important;
}

.flip-countdown.size-extra-small .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
  margin: 0 0.025em;
  font-size: 14px !important;
}

.perf-card:hover>#value {
  font-size: 1.875rem;
  font-weight: bold;
}

:root {
  --modal-origin: 'left center';
  --metrics-position: '0';
  --expand-metrics-color: ''
}

.spaceInLeft {
  -webkit-animation-name: spaceInLeft;
  animation-name: spaceInLeft;
}

@-webkit-keyframes spaceInLeft {
  0% {
    opacity: 0;
    transform-origin: var(--modal-origin, 'left center');
    transform: scale(0.2) translate(50%, 50%);
  }

  100% {
    opacity: 1;
    transform-origin: var(--modal-origin, 'left center');
    transform: scale(1) translate(0%, 0%);
  }
}

@keyframes spaceInLeft {
  0% {
    opacity: 0;
    transform-origin: var(--modal-origin, 'left center');
    transform: scale(0.2) translate(50%, 50%);
  }

  100% {
    opacity: 1;
    transform-origin: var(--modal-origin, 'left center');
    transform: scale(1) translate(0%, 0%);
  }
}

.spaceOutLeft {
  -webkit-animation-name: spaceOutLeft;
  animation-name: spaceOutLeft;
}

@-webkit-keyframes spaceOutLeft {
  0% {
    opacity: 1;
    transform-origin: var(--modal-origin, 'left center');
    transform: scale(1) translate(0%, 0%);
  }

  100% {
    opacity: 0;
    transform-origin: var(--modal-origin, 'left center');
    transform: scale(0) translate(50%, 50%);
  }
}

@keyframes spaceOutLeft {
  0% {
    opacity: 1;
    transform-origin: var(--modal-origin, 'left center');
    transform: scale(1) translate(0%, 0%);
  }

  100% {
    opacity: 0;
    transform-origin: var(--modal-origin, 'left center');
    transform: scale(0) translate(50%, 50%);
  }
}

.modal-space-in {
  transform: scaleY(0) scaleX(0);
  animation: spaceInLeft 1s forwards;
}

.modal-space-out {
  transform: scale(1);
  animation: spaceOutLeft 1s forwards;
}

input[type=number]::-webkit-inner-spin-button {
  opacity: 1 !important;
}

.spinner-text {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 48px;
  color: #ffffff;
  background: linear-gradient(to right, #4d4d4d 0, white 10%, #4d4d4d 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: spinnerText 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
  white-space: nowrap
}

@keyframes spinnerText {
  0% {
    background-position: 0;
  }

  60% {
    background-position: 180px;
  }

  100% {
    background-position: 180px;
  }
}

.div-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  transition-timing-function: ease-in-out;
}

.text-red-600 {
  color: #DC2626 !important;
}

.metricsAnimation {
  animation-duration: 2s;
  animation-name: slideout;
  width: fit-content;
}

@keyframes slideout {
  from {
    margin-left: var(--modal-origin, 0)
  }

  to {
    margin-left: 0vw;
  }

  0%,
  10% {
    opacity: 0.3
  }

  100% {
    opacity: 1
  }
}

.metricsAnimationReverse {
  animation-duration: 1s;
  animation-name: slidin;
  /* width: fit-content; */
}

@keyframes slidin {

  /* from { margin-right : 0vw }
    to   { margin-right : var(--modal-origin, 0) } */
  0%,
  10% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

.aisummarytable td{
  padding: 6px 0px;
  border-bottom: 1px solid #8223D2;
}

.aisummarytable th{
  padding: 8px 0px;
  border-bottom: 1px solid #8223D2;
  width: 10%;
  word-wrap: break-word
}

.paddingy4{
  padding: 4px 0px;
}

.aisummarytable{
  max-width: 98%;
  font-size: 14px;
  border: 1px solid #8223D2;
  border-radius: 8px;
}

.reportMenu {
  border: 1px solid #CB5AFF;
}

.reportMenu:hover {
  color: #CB5AFF;
  background-color: #333F48;
  border: 1px solid #CB5AFF;
}

.landingScreenStyle {
  background: linear-gradient(180deg, rgba(130, 35, 210, 0.8) 0%, rgba(103, 0, 184, 0.15) 86.46%, rgba(168, 57, 243, 0.15) 100%);
  border: 0px solid #CB5AFF;
}

.landingScreenStyle:hover{
  border: 5px solid #CB5AFF;
  border-radius: 30px;
}

.landingScreenStyle1 {
  background: linear-gradient(180deg, rgba(130, 35, 210, 0.8) 0%, rgba(103, 0, 184, 0.15) 86.46%, rgba(168, 57, 243, 0.15) 100%);
  border: 0px solid #CB5AFF;
}


.siteWrapper{
  width: 230px ;
  font-size: large;
  border-radius: 6px;
  padding-left: 12px;
}

/* .MetricsScrollClass
{
    width: 100%;
    overflow-y: auto;
}
.ContentMetricsScroll
{
    width: fit-content;
}

.MetricsScroll, .MetricsScroll .ContentMetricsScroll
{
    transform:rotateX(180deg);
    -ms-transform:rotateX(180deg); 
    -webkit-transform:rotateX(180deg); 
} */

/* ::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #6d6d6d; 
} */

/* .MetricsScroll::-webkit-scrollbar {
  height: 7px;
}

.MetricsScroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  border-radius: 5px;
}

.MetricsScroll::-webkit-scrollbar-thumb {
  height: 7px;
  background-color: #4d4d4d;
  cursor: crosshair;
  border-radius: 5px;
}

.MetricsScroll::-webkit-scrollbar-thumb:hover {
  background-color: #969696;
  border-radius: 5px;
}

.MetricsScroll::-webkit-scrollbar:vertical {
  display: none;
} */


.toggleButton {
  /* background-color: var(--expand-metrics-color, ''); */
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  height: 20px;
  position: relative;
  width: 35px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* .toggleButton:hover { background-color: lightgray; } */
.toggleButton:focus,
.toggleButton:active { outline: none; }
.toggleButton::-moz-focus-inner { border: 0; }

.pin {
  background-color: white;
  border-radius: 98px;
  height: 14px;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 14px;
  transition: left ease .5s;
}

.toggleButton.on {
  /* background-color: green; */
}

.toggleButton.on .pin {
  left: 16px;
}

.text-purple-color {
  color: #8223D2;
}
.text-grey-color {
  color: #6E767D;
}
.text-green-color {
  color: #00B574;
}
.text-blue-color {
  color: #4A68F9;
}
.text-yellow-color {
  color: #D2FA46;
}
.text-light-blue-color {
  color: #A839F3;
}
.text-red-color{
  color: #9F0000;
}
.bg-purple-color {
  background-color: #8223D2;
}
.bg-grey-color {
  background-color: #6E767D;
}
.bg-green-color {
  background-color: #00B574;
}
.bg-blue-color {
  background-color: #4A68F9;
}
.bg-yellow-color {
  background-color: #D2FA46;
}
.bg-light-blue-color {
  background-color: #A839F3;
}
.bg-red-color{
  background-color: #9F0000;
}

.slideRightCss{
	animation-name: slideDown;
	-webkit-animation-name: slideDown;	

	animation-duration: 1s;	
	-webkit-animation-duration: 1s;

	animation-timing-function: ease-in;	
	-webkit-animation-timing-function: ease-in-out;	

	visibility: visible !important;						
}

@keyframes slideDown {
	0% {
		transform: translateY(-2%);
	}
	50%{
		transform: translateY(6%);
	}
	65%{
		transform: translateY(2%);
	}			
	100% {
		transform: translateY(0%);
	}		
}

@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-10%);
	}
	50%{
		-webkit-transform: translateY(8%);
	}
	65%{
		-webkit-transform: translateY(-4%);
	}
	80%{
		-webkit-transform: translateY(4%);
	}
	95%{
		-webkit-transform: translateY(-2%);
	}			
	100% {
		-webkit-transform: translateY(0%);
	}	
}

.text-purple-secondary-color {
  color: #d648fd;
}
.text-grey-secondary-color {
  color: #6E767D;
}
.text-green-secondary-color {
  color: #00B574;
}
.text-blue-secondary-color {
  color: #4A68F9;
}
.text-yellow-secondary-color {
  color: #D2FA46;
}
.text-light-blue-secondary-color {
  color: #A839F3;
}
.text-light-blue-secondary-color1 {
  color: #32E7FF;
}
.text-dark-blue-secondary-color {
  color: #3CAFF7;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-form-item .ant-form-item-label >label {
  color: white;
}

.content{
margin-left: 170px;
  margin-right: 170px;
  margin-top: 20px;

}
.textfonts {
  text-align: center; 
  color: #3D3D3B; 
  font-size: 28px; 
  /* font-family: Lato; */
   font-weight: 400; 
   word-wrap: break-word;
  }
  .Logintitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

.loginBGImage {
  position: relative;
  height: 100vh;
}

.error{
    color: red;
    margin: 2px;
    padding: 15px;
    text-align: center;
}
.loginBGImage:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("./assets/icons/bgIcon.svg");
  background-position: top;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  z-index: -1;
  opacity: 0.1;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-picker {
  /* padding: 0px 4px 0px 0px !important; */
}

:where(.css-dev-only-do-not-override-ph9edi).ant-picker .ant-picker-input {
  /* border: 1px solid #0abe7c !important;
  padding: 4px !important;
  border-radius: 4px !important; */
}

.starhubHeader {
  font-size: 24px;
  /* font-family: Lato; */
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.starhubBackgroundImg {
  background-image: url("./assets/icons/StarHubBkgImg.png");
}

.SLASection .ant-progress-text {
  position: absolute !important;
  right: 40% !important;
  top: 30% !important;
  text-align: center;
  font-weight: 700 !important;
  color: black !important;
  width: auto !important;
}

.ant-progress-outer {
  padding: 0px !important;
}

.durationCircleCSS {
  border-radius: 199px;
  background: var(--star-hub-colors-gradient-00-bfb-203-f-182, linear-gradient(270deg, #03F182 0%, #00BFB2 100%));
}

.query-key {
  word-break: break-all;
}

.chart-container {
  height: 500px !important;
  width: 500px !important;
  margin-top: -100px !important;
}
