.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin-right: 0px !important;
  max-width: 220px !important;
  margin-left: auto !important;
  border-radius: 8px !important
}

.borderLeft{
  border-left: 1px solid white;
}
.ant-table-row-expand-icon-cell:nth-of-type(1){
  background: #333F48 !important
}
