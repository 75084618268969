.anticon {
    color: rgb(105,105,105) !important;
    cursor: not-allowed !important;
}
.anticon1 {
    color: #FFFDFA !important;
}

.disabledBtn {
    background-color: gray !important;
}
.disabledBtn2 {
    background-color: rgb(212, 198, 198) !important;
    color: rgb(184, 156, 156) !important;
    cursor: not-allowed;
}