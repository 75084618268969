/* DownloadReportsNew.css */

.download-reports-container {
    background-color: #ffffff;
    height: 100vh;
    padding-top: 2rem;
  }
  
  .header {
    display: flex;
    justify-content: center;
  }
  
  .starhub-logo {
    width: 14rem;
    height: 5rem;
    cursor: pointer;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .report-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .report-type {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1rem;
    color: #000000;
  }
  
  .dropdown-icon {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .dropdown-icon.open {
    transform: rotate(180deg);
  }
  
  .dropdown-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #000000;
  }
  
  .dropdown-label {
    margin-left: 0.5rem;
  }
  
  .date-picker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .download-button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .download-button {
    padding: 0.5rem 1rem;
    background-color: #4caf50;
    color: #ffffff;
    border: none;
    border-radius: 0.25rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .download-button:hover {
    background-color: #45a049;
  }

  .report-type-container.border {
    /* Add border styling when the component has the border class */
    border: 1px solid #ccc; /* You can customize the border style here */
    border-radius: 5px; /* Optional: If you want rounded corners */
  }
  
  /* Add additional custom styling as needed */
  